export default function useNumberInput() {
  // When keys are pressed, remove all characters except for numbers
  // and helper input keys (backspace, delete, tab, etc.)
  const skrNumberOnKeydown = (event: KeyboardEvent) => {
    const keyCode = event.keyCode

    const numbers0to9 = keyCode > 47 && keyCode < 58
    const numpad = keyCode > 95 && keyCode < 106
    const backspaceDeleteArrows = [8, 37, 39, 46].includes(keyCode)
    const ctrl = event.ctrlKey || event.metaKey
    const paste = ctrl && keyCode === 86
    const tab = keyCode === 9

    if (!(numbers0to9 || numpad || backspaceDeleteArrows || paste || tab)) {
      event.preventDefault()
    }
  }

  // When value is pasted, remove all characters except for numbers
  const skrNumberOnPaste = (event: ClipboardEvent) => {
    const pastedValue = event?.clipboardData?.getData('text')
    if (pastedValue) {
      const numbersOnly = pastedValue.replace(/[^0-9]+/g, '')
      return numbersOnly
    }
  }

  return {
    skrNumberOnKeydown,
    skrNumberOnPaste,
  }
}
