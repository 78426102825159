<script lang="ts" setup>
defineProps({
  modelValue: {
    type: String,
    required: true,
  },
})

const emit = defineEmits<{
  (event: 'update:modelValue', state: string): void
  (event: 'enter'): void
}>()

const { skrNumberOnKeydown, skrNumberOnPaste } = useNumberInput()
</script>

<template>
  <div class="tandialog">
    <div class="tandialog-input-wrapper">
      <v-text-field
        :model-value="modelValue"
        class="tandialog-input"
        type="text"
        maxlength="6"
        inputmode="numeric"
        variant="filled"
        data-cy="tan_input"
        @paste.prevent="emit('update:modelValue', skrNumberOnPaste($event)?.substring(0, 6) ?? '')"
        @keydown="skrNumberOnKeydown"
        @keydown.enter="emit('enter')"
        @update:model-value="emit('update:modelValue', $event.substring(0, 6))"
      />
    </div>
  </div>
</template>

<style lang="sass">
$char-w: 1ch
$gap: .5*$char-w
$n-char: 6
$in-w: $n-char*($char-w + $gap)

.tandialog
  display: flex
  justify-content: center
  &-input-wrapper
    .v-input .v-field__field
      padding: 1.5ch 0.25ch 1.5ch 1ch // Needed for better alignment

      input
        display: block
        border: none
        padding: 0
        width: $in-w
        max-height: none
        height: 2ch
        color: $c-text-alt
        background: repeating-linear-gradient(90deg, $c-text-alt 0, $c-text-alt $char-w, transparent 0, transparent $char-w + $gap) 0 100%/ #{$in-w - $gap} 2px no-repeat
        font: 3ch droid sans mono, consolas, monospace
        font-weight: 600
        letter-spacing: $gap
</style>
